<template>
  <div>
    <b-form-group
        label-cols-sm="4"
        :label="$t('Tags.TagEdit.Events.' + eventName + '.Fields.' + setting.name + '.name')"
        :label-for="setting.name"
    >
      <b-form-textarea
          :placeholder="$t('General.Form.NoSet')"
          type="text"
          v-model="valueField"
          :name="setting.name"
          v-b-tooltip.hover
          :title="setting.tooltip"
          :class="{ 'is-invalid': validate && $v.valueField.$error }"
          required
      ></b-form-textarea>
      <div v-if="validate && $v.valueField.$error" class="invalid-feedback">
        <span v-if="!$v.valueField.required">{{ $t('General.Form.Validate.Required') }}</span>
        <span
            v-if="!$v.valueField.maxLength"
        >{{ $t('General.Form.Validate.Maxlength', {count: $v.valueField.$params.maxLength.max}) }}</span>
      </div>
    </b-form-group>


    <div class="row mb-3">
      <small class="col pt-2 text-muted">
        {{ $t('Tags.TagEdit.Fields.' + setting.name + '.textWithButton') }}
      </small>
      <div class="col col-auto">
        <b-button
            variant="info"
            v-on:click="selectorSearchMode()"
        >
          {{ $t('Tags.TagEdit.Fields.' + setting.name + '.button_select_css') }}
        </b-button>
      </div>

    </div>

  </div>
</template>

<script>
import {tagsService} from "@/helpers/fakebackend/tags.service";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
  name: "cssSelector",
  props: ["dataTag", "setting", "value", "error", "validate", "eventName"],
  data: function () {
    return {
      valueField: ''
    }
  },
  validations: {
    valueField: { required, maxLength: maxLength(1000)}
  },
  watch: {
    valueField: function(val) {
      this.$v.$touch();

      this.$emit('change', val)
      this.$emit('input', val)
    },
    value: function (val) {
      this.valueField = val
    }
  },
  mounted() {
    this.valueField = this.value
  },
  methods: {
    selectorSearchMode() {
      let data = {
        projectId: this.dataTag.project_id,
      }
      tagsService.previewTag(data, 'cssSelector').then((data) => {
        if (data.url) {
          // this.previewMode = true
          window.open(data.url)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
