<template>
  <div class="d-flex align-items-center justify-content-center mt-5 ">
    <div class="col-lg-7 border p-5">

      <ee_email
          v-if="editMethod === 'ee_email'"
          :handler_id="handler_id"
          @onCloseHandler="$emit('onCloseHandler')"
          @onDeleteHandler = "deleteHandler"
      />
    </div>
  </div>
</template>

<script>
import { tagsService } from '@/helpers/fakebackend/tags.service';
import Swal from "sweetalert2";

export default {
  name: "editMethod",
  props: ["editMethod", "handler_id"],
  components: {
    ee_email: () => import('./_ee_email')
  },
  data: function () {
    return {

    }
  },
  methods: {
    ...tagsService,
    deleteHandler: function (handler_id) {
      Swal.fire({
        title: this.$t('General.Base.AreYouSure'),
        text: null,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('General.Base.Delete'),
        cancelButtonText: this.$t('General.Base.Cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteTagHandler({id: handler_id}).then(() => {
            this.$emit('onCloseHandler')
            this.$store.commit('tag/deleteHandlerMethod', handler_id)
          })
        }
      });
    }
  }
}
</script>

<style scoped>

</style>