<template>
  <div  class="h-100">
    <div class="card position-sticky" style="top: 80px">
      <div class="card-body">

        <b-row align-h="between">
        <b-col cols="auto">
          <h5>
            <i class="mdi  mdi-target mr-2"></i>
            {{$t('Tags.TagEdit.Step2.Triggers.Triggers')}}
          </h5>
        </b-col>
        <b-col cols="auto">
          <i v-b-tooltip.hover :title="$t('Tags.TagEdit.Step2.Triggers.TriggersTooltip')" class="far fa-question-circle font-size-16"></i>
        </b-col>
      </b-row>

        <b-card v-if="!triggers.length"
          class="mt-3 border border-info mb-3">
        <h5 class="mt-0 mb-4 text-info">
          <i class="mdi mdi-alert-circle-outline mr-3"></i>
          {{$t('Tags.TagEdit.Step2.Triggers.EmptyTriggers')}}
        </h5>
        <p class="card-text">
          {{$t('Tags.TagEdit.Step2.Triggers.TriggersDesc')}}
        </p>
      </b-card>

        <div class="mt-3 mb-3" v-if="triggers.length">
        <p>{{$t('Tags.TagEdit.Step2.Triggers.DragTriggersInfo')}}</p>

        <b-list-group class="newTriggerBlock" :style="{ maxHeight: heightWindow + 'px'}">
          <draggable
              class="list-group"
              :group="{ name: 'trigger', pull: 'clone', put: '' }"
              v-model="triggers"
              handle = ".itemDraggable:not(.btn)"

              :scroll-sensitivity="100"
              :force-fallback="true"
              ghost-class="ghost"
              :animation="100"
              :sort="false"
          >
            <b-list-group-item
                v-for="(trigger, index) in triggers"
                :key="index"
                class="border-left-0 border-right-0 "
            >

              <b-row align-h="between">
                <b-col class="itemDraggable grabCursor w-auto">
                  <div class="font-size-18">
                    <i :class="ListEvents[trigger.event].ico"></i>
                    {{trigger.name}}
                  </div>
                  <small class="text-muted ml-4">
                    {{$t('Tags.TagEdit.Step2.Conditions.Event')}}:
                    <span class="text-lowercase">{{ $t("Tags.TagEdit.Events." + trigger.event + ".name") }}</span>
                  </small>
                </b-col>
                <b-col cols="auto" class="pt-2">
                  <div class="text-right">
                    <b-button
                        v-b-modal.modal-edit-trigger
                        v-b-tooltip.hover.viewport
                        :title = "$t('General.Base.Edit')"
                        class="mr-1"
                        size="sm"
                        variant="outline-secondary"
                        @click.stop="modeNewTrigger = index"
                        @mousedown.stop=""
                        @drag.stop=""
                        @dragstart.stop = ""
                    >
                      <i  class="ri-settings-5-line"></i>
                    </b-button>
                    <b-button
                        @click="deleteTrigger(trigger, index)"
                        v-b-tooltip.hover.viewport
                        :title = "$t('General.Base.Delete')"
                        size="sm"
                        variant="outline-secondary">
                      <i  class="ri-delete-bin-line"></i>
                    </b-button>
                  </div>

                </b-col>
              </b-row>
            </b-list-group-item>
          </draggable>
        </b-list-group>
      </div>
<!-------------------------------------------------------------------------------------------NEW EVENT-->
        <EditTrigger v-model="modeNewTrigger" />

      </div>
    </div>
    <SidebarEvents />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import EditTrigger from "./_editTrigger";
import SidebarEvents from "./_sidebarEvents";
import { ListEvents } from "@/helpers/Tags/ListEvents";
import Swal from "sweetalert2";


export default {
  name: "triggers",
  components: { draggable, EditTrigger, SidebarEvents },
  props: ["dataTag"],
  data: function () {
    return {
      ListEvents: ListEvents,
      modeNewTrigger: null,
      heightWindow: window.innerHeight - 180 - 130 - 120
    }
  },
  computed: {
    triggers: function() {
      return this.$store.getters['triggers/getTriggers']
    },
    tag: function() {
      return this.$store.getters['tag/getTag']
    }
  },
  mounted() {
    this.$store.dispatch('triggers/getTriggers', this.tag.project_id)
  },
  methods: {
    deleteTrigger(trigger, index) {
      Swal.fire({
        title: this.$t("Tags.TagEdit.NewEvent.ConfirmDeleteTrigger.Title"),
        text: this.$t("Tags.TagEdit.NewEvent.ConfirmDeleteTrigger.Text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("Tags.TagEdit.NewEvent.ConfirmDeleteTrigger.Yes"),
        cancelButtonText: this.$t("Tags.TagEdit.NewEvent.ConfirmDeleteTrigger.No")
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('triggers/deleteTriggers', { trigger, index }).then(status => {
            if (status) {
              this.$bvToast.toast(this.$t('Tags.TagEdit.NewEvent.DeleteTriggerSuccess'), {
                title: this.$t('General.Toast.TitleNotification'),
                variant: 'success',
                solid: true
              })
            }
          })
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
