<template>
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <b-card-title>
            <h5 class="">
              <i class="mdi  mdi-bullseye-arrow mr-2"></i>
              {{$t('Tags.TagEdit.Step2.Conditions.SettingActivate')}}
            </h5>
          </b-card-title>
          <b-card-text>
            <p class="card-text mt-3">{{$t('Tags.TagEdit.Step2.Conditions.SettingActivateText')}}</p>

            <div v-for="(group, indexGroup) in tag.tTagGroupTriggers"
                 :key="indexGroup"
            >
              <b-card
                  header-class="bg-transparent "
                  body-class="pt-0 "
                  :class="['mb-0', 'mt-4', 'border', 'border-' + getNameClassByType(group.exception)] ">

                <template v-slot:header>
                  <b-badge v-if="group.exception == 0" class="badgeGroupException" variant="success">
                    {{$t('Tags.TagEdit.Step2.Conditions.Activation')}}
                  </b-badge>
                  <b-badge v-if="group.exception == 1" class="badgeGroupException" variant="danger">
                    {{$t('Tags.TagEdit.Step2.Conditions.Exception')}}
                  </b-badge>
                  <b-badge v-if="group.exception == 2" class="badgeGroupException" variant="primary">
                    {{$t('Tags.TagEdit.Step2.Conditions.Hidden')}}
                  </b-badge>
                  <b-btn-close
                      v-b-tooltip.hover
                      :title="$t('Tags.TagEdit.Step2.Conditions.DeleteGroup')"
                      @click="delGroup(indexGroup)"
                      class="delGroupException pl-1 pr-1 font-size-16">
                  </b-btn-close>

                  <b-row align-h="between">
                    <b-col cols="auto">
                      <h5 class="pt-1 font-size-14">
                        {{$t('Tags.TagEdit.Step2.Conditions.OrderExecution')}}
                      </h5>
                    </b-col>
                    <b-col cols="auto">
                      <i v-b-tooltip.hover title="text" class="far fa-question-circle mr-2"></i>
                      <b-button-group class="text-right mt-4 mt-md-0 btn-group-sm">

                        <b-button
                            @click="group.sequence = '0'"
                            :variant="group.sequence == '0' ? 'success' : 'light'"
                            data-toggle="buttons"
                        >
                          {{$t('Tags.TagEdit.Step2.Conditions.Random')}}
                        </b-button>
                        <b-button
                            @click="group.sequence = '1'"
                            :variant="group.sequence == '1' ? 'success' : 'light'"
                            data-toggle="buttons"
                        >
                          {{$t('Tags.TagEdit.Step2.Conditions.Consistent')}}
                        </b-button>
                      </b-button-group>
                    </b-col>
                  </b-row>


                  <hr :class="['mb-0', 'border-' + getNameClassByType(group.exception)] ">
                </template>

                <b-list-group>
                  <draggable
                      class="list-group draggable3"
                      @start="drag=true"
                      @change="addTrigger($event, indexGroup)"
                      group="trigger"
                      draggable=".itemDraggable"
                      :animation="100"
                      ghost-class="ghost"
                      handle=".itemDraggable"
                      v-model = "fakeList"
                      :ref="'draggable_' + indexGroup"
                  >
                    <b-list-group-item
                        v-for="(triggerInGroup, indexTrigger) in group.triggersInGroups"
                        :key="indexTrigger"
                        class="itemDraggable border-left-0 border-right-0 grabCursor"
                    >
                      <b-row align-h="between">
                        <b-col cols="6">
                          <div class="font-size-18">
                            <i :class="ListEvents[triggerInGroup.trigger.event].ico"></i>
                            {{triggerInGroup.trigger.name}}
                          </div>
                          <small class="text-muted ml-4">{{$t('Tags.TagEdit.Step2.Conditions.Event')}}: <span class="text-lowercase">{{ $t("Tags.TagEdit.Events." + triggerInGroup.trigger.event + ".name") }}</span></small>
                        </b-col>
                        <b-col cols="auto pt-0">
                          <div class="text-right">
<!--                            <b-button v-b-tooltip.hover title="Удалить из списка" size="sm" variant="outline-danger">-->
<!--                              <i  class="ri-settings-5-line "></i>-->
<!--                            </b-button>-->
                            <b-button v-b-tooltip.hover :title="$t('Tags.TagEdit.Step2.Conditions.RemoveFromList')" @click="removeTrigger(indexGroup, indexTrigger)" class="mb-1" size="xsm" variant="outline-danger">
                              <i  class="mdi mdi-sort-variant-remove "></i>
                            </b-button>
                          </div>
                          <div>
                            <small>
                              <i :title="$t('Tags.TagEdit.Step2.Conditions.TooltipTimeAreaDesc')"  v-b-tooltip.hover class="far fa-question-circle"></i>
                              {{$t('Tags.TagEdit.Step2.Conditions.LeadTime')}}:
                              <b-button v-b-modal.modal-lead-time @click="leadTimeEdit = { indexGroup, indexTrigger }" class="link-outline-dashed pl-0 pr-0 ml-0 mr-1" :pressed="true" variant="link" size="xsm">
                                <span v-if = "triggerInGroup.lead_time === 'page'">{{$t('Tags.TagEdit.Step2.Conditions.LeadTimePage')}}</span>
                                <span v-else-if = "triggerInGroup.lead_time === 'session'">{{$t('Tags.TagEdit.Step2.Conditions.LeadTimeSession')}}</span>
                                <span v-else-if = "triggerInGroup.lead_time === '0'">{{$t('Tags.TagEdit.Step2.Conditions.LeadTimeAll')}}</span>
                                <span v-else >{{triggerInGroup.lead_time_text}}</span>
                              </b-button>
                              <b-button v-b-modal.modal-lead-time class="ml-0 mr-0" @click="leadTimeEdit = { indexGroup, indexTrigger }" variant="outline-primary" size="xsm">{{$t('General.Base.Edit')}}</b-button>
                            </small>
                          </div>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </draggable>
                  <div class="m-4 text-center font-size-16 draggableInfoText">{{$t('Tags.TagEdit.Step2.Conditions.DragTriggerInfo')}}</div>
                </b-list-group>

              </b-card>
              <div class="mt-3 mb-3 font-weight-bold font-size-16 conditionsDelimiterOr">{{$t('General.Base.Or')}}</div>
            </div>

            <div class="border border-success">
              <draggable
                  class="list-group draggable6"
                  @change="newGroup(0)"
                  group="trigger"
                  draggable=".itemDraggable"
                  :list="triggerGroupsNew"
              >
              </draggable>
              <p class="m-5 text-center font-size-16 draggableInfoText">{{$t('Tags.TagEdit.Step2.Conditions.DragTriggerInfo')}}</p>
            </div>


            <b-row class="mt-5 mb-0 pb-0" align-h="between">
              <b-col cols="auto">
                <h5 class="mb-0">
                  <i class="mdi mdi-bullseye mr-2"></i>
                  {{$t('Tags.TagEdit.Step2.Conditions.ConditionForHideWidget')}}
                </h5>
              </b-col>
              <b-col cols="auto">
                <i v-b-tooltip.hover :title="$t('Tags.TagEdit.Step2.Conditions.ConditionForHideWidgetTooltip')"
                   class="far fa-question-circle font-size-16"></i>
              </b-col>
            </b-row>

            <div class="mt-3 border border-primary">
              <draggable
                  class="list-group draggable6"
                  @change="newGroup(2)"
                  group="trigger"
                  draggable=".itemDraggable"
                  :list="triggerGroupsNew"
              >
              </draggable>
              <p class="m-5 text-center font-size-16 draggableInfoText">
                {{$t('Tags.TagEdit.Step2.Conditions.DragTriggerInfo')}}
              </p>
            </div>


            <b-row class="mt-5 mb-0 pb-0" align-h="between">
              <b-col cols="auto">
                <h5 class="mb-0">
                  <i class="mdi mdi-cancel mr-2"></i>
                  {{$t('Tags.TagEdit.Step2.Conditions.ConditionForCancelWidget')}}
                </h5>
              </b-col>
              <b-col cols="auto">
                <i v-b-tooltip.hover :title="$t('Tags.TagEdit.Step2.Conditions.ConditionForCancelWidgetTooltip')"
                   class="far fa-question-circle font-size-16"></i>
              </b-col>
            </b-row>

            <div class="mt-3 border border-danger">
              <draggable
                  class="list-group draggable6"
                  @change="newGroup(1)"
                  group="trigger"
                  draggable=".itemDraggable"
                  :list="triggerGroupsNew"
              >
              </draggable>
              <p class="m-5 text-center font-size-16 draggableInfoText">
                {{$t('Tags.TagEdit.Step2.Conditions.DragTriggerInfo')}}
              </p>
            </div>

          </b-card-text>
        </div>
      </div>
    </div>

    <div class="col-6" >
        <Triggers :dataTag="dataTag"/>
    </div>

    <ModalLeadTime :leadTimeEdit = leadTimeEdit  />

  </div>
</template>

<script>
import draggable from "vuedraggable";
import Triggers from "./_triggers"
import { ListEvents } from "@/helpers/Tags/ListEvents";
import ModalLeadTime from "./_modalLeadTime"

export default {
  name: "conditionActivate",
  components: { draggable, Triggers, ModalLeadTime},
  props: ["dataTag"],
  data: function () {
    return {
      ListEvents: ListEvents,
      triggerGroupsNew: [],
      fakeList: [],
      leadTimeEdit: null
    }
  },
  computed: {
    tag: function() {
      return this.$store.getters['tag/getTag']
    }
  },
  methods: {
    // sortInGroup(event, groupIndex) {
    //   if (event.pullMode === "clone") {
    //     return
    //   }
    //   console.log(event, groupIndex)
    //
    // },
    removeTrigger(groupIndex, indexTrigger) {
      this.$store.commit('tag/removeTriggers', { groupIndex, indexTrigger })
    },
    addTrigger(event, groupIndex) {
      if (event.moved !== undefined) {
        let oldIndex = event.moved.oldIndex
        let newIndex = event.moved.newIndex
        this.$store.commit('tag/sortTriggers', { groupIndex, oldIndex, newIndex })
        return
      }

      this.fakeList = []
      let trigger = event.added.element
      let indexElem = event.added.newIndex

      this.$store.dispatch('tag/addTriggerInGroup', { groupIndex, trigger, indexElem })
    },
    newGroup(exception) {
      let trigger = this.triggerGroupsNew[0]
      this.$store.dispatch('tag/addNewGroupTrigger', this.tag.project_id).then( groupIndex => {


        this.$store.commit('tag/setException', {groupIndex: groupIndex, exception: exception})

        // this.tag.tTagGroupTriggers[groupIndex].exception = exception
        this.$store.dispatch('tag/addTriggerInGroup', { groupIndex, trigger })
        this.$refs['draggable_' + groupIndex][0].updatePosition(0,1)
        this.$refs['draggable_' + groupIndex][0].updatePosition(1,0)
      })
      this.triggerGroupsNew = []
    },
    delGroup(groupIndex) {
      this.$store.commit('tag/deleteGroup', { groupIndex })
    },
    getNameClassByType(type) {
      if (type == '0') {
        return 'success'
      }
      if (type == '1') {
        return 'danger'
      }
      if (type == '2') {
        return 'primary'
      }
    }
  }
}
</script>

<style scoped>

</style>
