<template>
  <b-sidebar
      id="eventsList"
      :title="$t('Tags.TagEdit.SideBar.Title')"
      shadow
      right
      backdrop
      width="40%"
      bg-variant="white"
  >
    <b-list-group
        v-for="(events, nameGroup) in GroupsEvent"
        :key="nameGroup"
        class="mt-3 "
    >
      <h5 class="ml-4 mt-4  pb-3 mr-3 font-weight-light">{{ $t("Tags.TagEdit.EventsGroup." + nameGroup) }}</h5>
      <b-list-group-item
          v-for="(nameEvent, index) in events"
          :key="index"
          button
          v-b-toggle.eventsList
          @click="selectEvent(nameEvent)"
          class="flex-column align-items-start border-vertical-no-first-no-last">
        <h5 class="mb-1">
          <i :class="ListEvents[nameEvent].ico"></i>
          {{ $t("Tags.TagEdit.Events." + nameEvent + ".name") }}
        </h5>
        <p class="mb-1 pl-4">
          {{ $t("Tags.TagEdit.Events." + nameEvent + ".description") }}
        </p>
      </b-list-group-item>
    </b-list-group>
  </b-sidebar>
</template>

<script>
import { ListEvents, GroupsEvent } from "@/helpers/Tags/ListEvents"

export default {
  name: "sidebarEvents",
  data: function () {
    return {
      ListEvents: ListEvents,
      GroupsEvent: GroupsEvent
    }
  },
  methods: {
    selectEvent(nameEvent) {
      this.$store.commit('triggers/setSideBarSelectEvent', nameEvent)
    }
  }
}
</script>

<style scoped>

</style>
