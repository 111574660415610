<template>
  <div>
    <b-card class="mb-0 border border-primary">

    <h6 class="mt-0 mb-4 ">
      <i class="mdi mdi-moon-new mr-3"></i>
      {{ $t("Tags.TagEdit.NewEvent.NewTriggerTitle") }}
    </h6>

    <b-button variant="primary" v-b-toggle.eventsList @click="listenerSelectEvent = true; submitted = false">
      <span v-if="!trigger.event">{{ $t("Tags.TagEdit.NewEvent.ButtonSelectEvent") }}</span>
      <span v-if="trigger.event">{{ $t("Tags.TagEdit.NewEvent.ButtonChangeEvent") }}</span>
    </b-button>
    <small class="text-muted ml-3">{{ $t("Tags.TagEdit.NewEvent.InfoButtonText") }}</small>
    </b-card>

  <b-modal
      ref="modal-edit-trigger"
      id="modal-edit-trigger"
      size="lg"
      :title="$t('Tags.TagEdit.NewEvent.NewTriggerTitle')"
      title-class="font-18"
      hide-footer
  >

<!--        <b-card class="mb-0 border border-primary">-->
    <div v-if="trigger.event" class="mt-4">

      <b-row align-h="between">
        <b-col cols="auto">
          <h5>
            <i :class="ListEvents[trigger.event].ico"></i>
            {{ $t("Tags.TagEdit.Events." + trigger.event + ".name") }}
          </h5>
        </b-col>
        <b-col cols="auto">
          <i
              v-b-tooltip.hover
              :title = "$t('Tags.TagEdit.Events.' + trigger.event + '.tooltip')"
              class="far fa-question-circle font-size-16"></i>
        </b-col>
      </b-row>

      <b-form-group
          label-cols-sm="4"
          :label="$t('Tags.TagEdit.NewEvent.TriggerName')"
          label-for="trigger.name"
          v-b-tooltip.hover
          :title="$t('Tags.TagEdit.NewEvent.TriggerNameTooltip')"
      >
        <b-input-group>
          <b-form-input
              :placeholder="$t('General.Form.NoSet')"
              type="text"
              v-model="trigger.name"
              name="trigger.name"
              required
              :class="{ 'is-invalid': submitted && $v.trigger.name.$error }"
          ></b-form-input>
          <div v-if="submitted && $v.trigger.name.$error" class="invalid-feedback">
            <span v-if="!$v.trigger.name.required">{{ $t('General.Form.Validate.Required') }}</span>
            <span
                v-if="!$v.trigger.name.maxLength"
            >{{ $t('General.Form.Validate.Maxlength', {count: $v.trigger.name.$params.maxLength.max}) }}</span>
          </div>
        </b-input-group>
      </b-form-group>

      <div
          v-for="(field, index) in ListEvents[trigger.event].settings"
          :key="index"
      >
        <FieldTypeString
            v-if="field.type === 'string'"
            :dataTag="dataTag"
            :setting="field"
            v-model="trigger.data[field.name]"
            :eventName = "trigger.event"
        />

        <FieldTypeCssSelector
            v-if="field.type === 'cssSelector'"
            :dataTag="dataTag"
            :setting="field"
            v-model="trigger.data[field.name]"
            :validate="submitted"
            :eventName = "trigger.event"
        />

        <ConditionString
            v-if="field.type === 'conditionString'"
            :dataTag="dataTag"
            :setting="field"
            v-model="trigger.data[field.name]"
            :validate="submitted"
            :eventName = "trigger.event"
        />

        <TimeField
            v-if="field.type === 'time'"
            :dataTag="dataTag"
            :setting="field"
            v-model="trigger.data[field.name]"
            :validate="submitted"
            :eventName = "trigger.event"
        />

        <SelectField
            v-if="field.type === 'select'"
            :dataTag="dataTag"
            :setting="field"
            v-model="trigger.data[field.name]"
            :validate="submitted"
            :eventName = "trigger.event"
        />

        <RangeDateField
            v-if="field.type === 'rangeDate'"
            :dataTag="dataTag"
            :setting="field"
            v-model="trigger.data[field.name]"
            :validate="submitted"
            :eventName = "trigger.event"
        />
      </div>

      <div class="mt-4">
        <b-button variant="success" @click="saveTrigger">
          <span v-if="trigger.id === 0">{{ $t("Tags.TagEdit.NewEvent.NewTriggerButtonText") }}</span>
          <span v-if="trigger.id !== 0">{{ $t("Tags.TagEdit.NewEvent.SaveButtonText") }}</span>
        </b-button>
        <b-button class="ml-2" variant="light" @click="cancel">
          {{ $t("Tags.TagEdit.NewEvent.CancelButtonText") }}
        </b-button>
      </div>

      <hr>
      <div
          v-if="$t('Tags.TagEdit.Events.' + trigger.event + '.description')"
      >
        <div v-html="$t('Tags.TagEdit.Events.' + trigger.event + '.description')"></div>
      </div>
    </div>
<!--  </b-card>-->
  </b-modal>
  </div>
</template>

<script>
import FieldTypeString from "./triggerFiels/_string"
import FieldTypeCssSelector from "./triggerFiels/_cssSelector"
import ConditionString from "./triggerFiels/_conditionString"
import TimeField from "./triggerFiels/_time"
import SelectField from "./triggerFiels/_select"
import RangeDateField from "./triggerFiels/_rangeDate"

import {ListEvents} from "@/helpers/Tags/ListEvents";
import {maxLength, required} from "vuelidate/lib/validators";
import lodash from "lodash";

export default {
  name: "editTrigger",
  components: { FieldTypeString, FieldTypeCssSelector, ConditionString, TimeField, SelectField, RangeDateField },
  props: ["dataTag", "value"],
  data: function () {
    return {
      ListEvents: lodash.cloneDeep(ListEvents),
      newTrigger: {
        id: 0,
        event: null,
        data: {},
        name: ''
      },
      trigger: {},
      listenerSelectEvent: false,
      submitted: false
    }
  },
  validations: {
    trigger: {
      name: { required, maxLength: maxLength(100)}
    }
  },
  computed: {
    SideBarSelectEvent: function() {
      return this.$store.getters['triggers/getSideBarSelectEvent']
    },
    triggers: function() {
      return this.$store.getters['triggers/getTriggers']
    },
    tag: function() {
      return this.$store.getters['tag/getTag']
    }
  },
  watch: {
    SideBarSelectEvent: function(val) {
      if (!this.listenerSelectEvent) {
        return
      }
      if (val !== false) {
        this.$refs['modal-edit-trigger'].show()
        this.setEvent(val)
      }
    },
    value: function(val) {
      if (val === null) {
        this.trigger = lodash.cloneDeep(this.newTrigger)
        return
      }
      this.trigger = lodash.cloneDeep(this.triggers[val])
    }
  },
  mounted() {
    if (this.value === null) {
      this.createNewTrigger()
    }
  },
  methods: {
    createNewTrigger() {
      this.trigger = lodash.cloneDeep(this.newTrigger)
    },
    setEvent(nameEvent) {
      this.listenerSelectEvent = false
      this.$store.commit('triggers/setSideBarSelectEvent', null)

      if (this.trigger.event === nameEvent) {
        return
      }

      this.createNewTrigger()

      this.trigger.event = nameEvent
      for (let field in ListEvents[this.trigger.event].settings) {
        if (ListEvents[this.trigger.event].settings[field].required) {
          this.$set(this.trigger.data, ListEvents[this.trigger.event].settings[field].name, null)
        }
      }
    },
    getSettingField(fieldName) {
      for (let option in ListEvents[this.trigger.event].settings) {
        if (ListEvents[this.trigger.event].settings[option].name === fieldName) {
          return ListEvents[this.trigger.event].settings[option]
        }
      }
      return null
    },
    saveTrigger() {
      this.submitted = true;
      this.$v.$touch()

      for (let field in this.trigger.data) {
        let field_setting = this.getSettingField(field)
        if (field_setting.required && this.trigger.data[field] === null) {
          return
        }
      }

      if (this.$v.$invalid) {
        return
      }

      let trigger = this.trigger
      let project_id = this.tag.project_id

      if (this.trigger.id !== 0) {
        let index = this.value
        this.$store.dispatch('triggers/saveTriggers', {trigger, project_id, index}).then(status => {
          if (status) {
            this.$bvToast.toast(this.$t('Tags.TagEdit.NewEvent.UpdateTriggerSuccess'), {
              title: this.$t('General.Toast.TitleNotification'),
              variant: 'success',
              solid: true
            });
            this.cancel()
          }
        })
        return
      }
      // new trig
      this.$store.dispatch('triggers/createTriggers', {trigger, project_id}).then(status => {
        if (status) {
          this.$bvToast.toast(this.$t('Tags.TagEdit.NewEvent.NewTriggerSuccess'), {
            title: this.$t('General.Toast.TitleNotification'),
            variant: 'success',
            solid: true
          });
          this.cancel()
        }
      })

    },
    cancel() {
      if (this.trigger.id === 0) {
        this.trigger.event = null
      }
      this.trigger = lodash.cloneDeep(this.newTrigger)
      this.$emit('change', null)
      this.$emit('input', null)
      this.$refs['modal-edit-trigger'].hide()
    }
  }
}
</script>

<style scoped>

</style>
