<template>
  <div>
    <b-form-group
        label-cols-sm="4"
        :label="$t('Tags.TagEdit.Events.' + eventName + '.Fields.' + setting.name + '.name')"
        :label-for="'timeTerm' + setting.name"
    >
      <TermTime v-model="time" :inputName = "'timeTerm' + setting.name" />

      <div v-if="validate && $v.valueField.$error" class="invalid-feedback">
        <span v-if="!$v.valueField.required">{{ $t('General.Form.Validate.Required') }}</span>
        <span
            v-if="!$v.valueField.maxLength"
        >{{ $t('General.Form.Validate.Maxlength', {count: $v.valueField.$params.maxLength.max}) }}</span>
      </div>

    </b-form-group>
  </div>
</template>

<script>
import TermTime from "@/components/inputs/TermTime";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
  name: "timeField",
  components: { TermTime },
  props: ["dataTag", "setting", "value", "validate", "eventName"],
  validations() {
    let rules = {
      valueField: { maxLength: maxLength(250)}
    }
    if (this.setting.required) {
      rules.valueField = {required, ...rules.valueField}
    }
    return rules
  },
  data: function() {
    return {
      time: null
    }
  },
  mounted() {
    this.time = this.value
  },
  watch: {
    time: function(val) {
      this.$v.$touch();
      this.$emit('change', val)
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped>

</style>