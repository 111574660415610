<template>
    <b-form-group
        label-cols-sm="4"
        :label="$t('Tags.TagEdit.Events.' + eventName + '.Fields.' + setting.name + '.name')"
        :label-for="'conditionString' + setting.name"
    >

      <b-input-group>
        <b-form-select
            v-if="conditionOptions.length"
            class="col-4"
            :options="conditionOptions"
            v-model="valueCondition"
            v-b-tooltip.hover
            :title="$t('Tags.TagEdit.Fields.conditionString.conditionFieldTooltip')"
        ></b-form-select>

        <multiselect
            v-model="selectValue"
            :multiple="setting.multiple"
            track-by="code"
            label="name"
            :options="options"
            :class="{'is-invalid': validate && $v.selectValue.$error, 'col-8': conditionOptions.length }"
        />
        <div v-if="validate && $v.selectValue.$error" class="invalid-feedback">
          <span v-if="!$v.selectValue.required">{{ $t('General.Form.Validate.Required') }}</span>
        </div>

      </b-input-group>
  </b-form-group>
</template>

<script>
import Multiselect from "vue-multiselect";
import {tagsService} from "@/helpers/fakebackend/tags.service";
import {required} from "vuelidate/lib/validators";
import lodash from "lodash";
import LangList from "@/helpers/Tags/LangList.json"

// import lodash from "lodash";

export default {
  name: "selectField",
  components: { Multiselect },
  props: ["dataTag", "setting", "value", "validate", "eventName"],
  data: function () {
    return {
      selectValue: null,
      options: [],
      conditionOptions: [],
      valueCondition: null,
      patternConditions: {
        'contains': { value: 'contains', text: this.$t('Tags.TagEdit.Over.Conditions.contains') },
        'noContains': { value: 'noContains', text: this.$t('Tags.TagEdit.Over.Conditions.noContains') }
      },
    }
  },
  validations: {
    selectValue: { required }
  },
  watch: {
    selectValue: function(val) {
      this.$v.$touch();

      let data = {
        condition: this.valueCondition,
        value: null
      }
      if (lodash.isArray(val)) {
        data.value = []
        val.forEach((e) => {
          data.value.push(e.code)
        })
      } else {
        data.value = val.code
      }

      this.$emit('change', data)
      this.$emit('input', data)
    },
    validate: function () {
      this.$v.$touch();
    }
  },
  methods: {
    ...tagsService,
    // localizedOptions(options) {
    //   options = options.map((elem) => {
    //     let elem2 = lodash.clone(elem)
    //     elem2.name = this.$t('Constructor.Props.' + this.blockProps + '.' + this.prop.name + '.options.' + elem2.name)
    //     return elem2
    //   });
    //   return options
    // },

    applyModeTag: async function() {
      this.$store.dispatch('projects/getCurrentProjects').then((project) => {
        if (!project) {
          return
        }
        this.getTags({project_id: project.id}).then(result => {
          result.forEach(tag => {
            this.options.push({
              code: tag.id,
              name: 'ID ' + tag.id + ': ' + tag.name
            })
          })
        })
      })
    },
    localizedOptions(options) {

      options = options.map((elem) => {
        let elem2 = lodash.clone(elem)
        if (elem2.code === 'self') {
          elem2.name = this.$t('Tags.TagEdit.Over.SelfTag')
        }
        // elem2.name = this.$t('Tags.TagEdit.Events.' + this.eventName + '.Fields.' + this.setting.name + '.name' + elem2.name)
        return elem2
      });
      return options
    },
    applyOptions: async function() {
      if (this.setting.mode === 'default') {
        // example
        // {
        //   name: 'absolute',
        //   code: 'absolute'
        // }

        this.options = this.localizedOptions(this.setting.options)
      } else if (this.setting.mode === 'tag') {
        if (this.setting.options !== undefined) {
          this.options = this.localizedOptions(this.setting.options)
        }
        await this.applyModeTag()
      } else if (this.setting.mode === 'lang') {
        for (let i in LangList) {
          this.options.push(
              {
                code: i,
                name: LangList[i].name + ' ('+ LangList[i].nativeName +')'
              }
          )
        }
      }
    }
  },
  mounted() {
    if (this.setting.condition !== undefined) {
      this.setting.condition.forEach(elem => {
        this.conditionOptions.push(
            this.patternConditions[elem]
        )
      })
    }


    this.applyOptions().then(() => {
      if (this.value) {
        if (lodash.isArray(this.value.value)) {
          this.selectValue = []
        }

        this.valueCondition = this.value.condition
        for (let i in this.options) {
          if (lodash.isString(this.value.value) && this.options[i].code === this.value.value) {
            this.selectValue = {code: this.value.value, name: this.options[i].name}
          }
          if (lodash.isArray(this.value.value) && this.value.value.includes(this.options[i].code)) {
            this.selectValue.push({code: this.options[i].code, name: this.options[i].name})
          }
        }
      } else if (this.conditionOptions[0] !== undefined) {
        this.valueCondition = this.conditionOptions[0].value
      }
    })
  }
}
</script>

<style scoped>
</style>