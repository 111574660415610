<template>
  <div class="row">
<!--    <div v-if="checkNotSaveWidget" class="col-12">-->
<!--      <b-alert show variant="warning">-->
<!--        В локальной памяти есть не сохраненный виджет.-->
<!--        <b-button @click="showDescriptionTextInNotSaveAleert = true" variant="link" size="sm">Что с этим делать?</b-button>-->
<!--        <b-button class="ml-2" variant="success" size="sm" @click="saveWidget()">Сохранить</b-button>-->

<!--        <ul v-if="showDescriptionTextInNotSaveAleert">-->
<!--          <li>Вы можете продолжить разработку этого виджета</li>-->
<!--          <li>Удалить его и выбрать новый виджет нажав на кнопку "сменить виджет"</li>-->
<!--          <li>Сохранить виджет и ввернутся к нему позже. Для этого нажмите кнопку сохранить и смените виджет.</li>-->
<!--        </ul>-->

<!--      </b-alert>-->
<!--      {{getTagEdit}}-->
<!--    </div>-->

<!--    <LoaderBig v-if="loading === true" />-->

    <b-card v-if="checkSelectTag" class="col-12">
      <b-row>
        <b-col>

          <div class="d-flex align-items-center">
            <h4 class="mb-0">{{$t('Tags.TagEdit.Widget.CurrentWidget')}}: </h4>
            <b-badge
                :variant="getTagEdit.status === 1 ? 'success' : 'secondary'"
                class="ml-3 mt-1"
            >
              {{ $t("Tags.Status." + getTagEdit.status)}}
            </b-badge>

            <b-badge
                v-if="getTagEdit.public_tag_source_id	!== null && getTagEdit.tag_source_id !== getTagEdit.public_tag_source_id"
                size="sm"
                variant="danger"
                class="ml-1 mt-1 align-middle"
                v-b-tooltip.hover.viewport = "$t('Tags.Table.MsgWrongVersionText')"
            >
              {{$t('Tags.Table.MsgWrongVersion')}}
              <i class="far fa-question-circle align-middle ml-1"></i>
            </b-badge>
          </div>
          <p class="mb-2 mt-2">
            <b>{{$t('Tags.Table.Name')}}: </b>
            <span v-if="!getTagEdit.name">{{$t('Tags.Table.NoName')}}</span>
            <span v-else>{{getTagEdit.name}}</span>
            <small> (ID: {{getTagEdit.tag_source_id}})</small>
          </p>
          <p>
            <b>{{$t('Tags.Table.Version')}}: </b>
            <span v-if="!getTagEdit.version">0</span>
            <span v-else>{{getTagEdit.version}}</span>
          </p>

<!--          <span class="small ml-1">({{$t('Tags.TagEdit.Widget.Updated')}}: <b>{{item.date_update | date('datetime')}}</b>)</span>-->
<!--          <p class="small m-0 p-0">{{$t('Tags.TagEdit.Widget.VersionFrom')}}: <b>{{item.date_create | date('datetime')}}</b></p>-->

          <div class="mt-4">
            <b-button @click="changeWidget" variant="primary" size="sm">{{$t('Tags.TagEdit.Step1.ChangeWidget')}}</b-button>

            <router-link
                class="btn btn-success ml-2 btn-sm"
                :to="'/constructor/' + getTagEdit.tag_source_id + '?r=tags/' + getTagEdit.id"
            >
              <i class="ri-settings-line align-middle"></i>
              {{$t('Tags.TagEdit.Step1.GoToConstructor')}}
            </router-link>

            <b-button
                v-if="getTagEdit.public_tag_source_id	!== null && getTagEdit.tag_source_id !== getTagEdit.public_tag_source_id"
                size="sm"
                variant="warning"
                class="ml-3"
                @click="publicationTag(getTagEdit.id)"
            >
              <i class="mdi mdi-reload"></i>
              {{$t('Tags.Table.Public')}}
            </b-button>
          </div>
        </b-col>
        <b-col>

          <h6 class="card-title">{{$t('Tags.TagEdit.Widget.AllWidgetVersions')}}</h6>
          <b-overlay :show="loadingVirsionsWidget" rounded="sm">
            <div v-if="!versionsCurrentTag" class="text-center mt-5">
                <p class="d-inline">
                  {{$t('Tags.TagEdit.Step1.HistoryVersionsWidgetNone')}}
                </p>
            </div>

            <b-list-group v-if="versionsCurrentTag" style="max-height: 210px; overflow-y: auto">
              <b-list-group-item
                  v-for="(item, index) in versionsCurrentTag"
                  :key="index"
                  class="text-muted"
              >
                <b-row align-h="between">
                  <b-col cols="auto">
                    <span v-if="item.name === ''">{{$t('Widget.Widget')}} ID: {{item.id}}</span>
                    <span v-else>ID: {{item.id}} {{item.name}}</span>
                    <span v-if = "item.date_update" class="small ml-1">({{$t('General.Base.Updated')}}: <b>{{item.date_update | date('datetime')}}</b>)</span>
                    <span v-else class="small ml-1">({{$t('General.Base.Created')}}: <b>{{item.date_create | date('datetime')}}</b>)</span>
                    <p class="small m-0 p-0">{{$t('Tags.TagEdit.Widget.VersionFrom')}}: <b>{{item.date_create | date('datetime')}}</b></p>
                  </b-col>
                  <b-col cols="auto">
                    <b-button v-if="getTagEdit.tag_source_id === item.id" variant="primary" disabled size="sm" class="">
                      <i class="ri-checkbox-fill align-middle"></i>
                      {{$t('General.Base.Selected')}}
                    </b-button>

                    <b-button v-else @click="selectOverVersionWidget(item.id)" variant="primary" size="sm" class="">
                      <i class="ri-checkbox-line align-middle "></i>
                      {{$t('General.Base.Select')}}
                    </b-button>

                    <router-link
                        class="btn btn-success ml-2 btn-sm"
                        :to="'/constructor/' + item.id + '?r=tags/' + item.tag_id"
                        :title="$t('Tags.TagEdit.Step1.GoToConstructor')"
                    >
                      <i class="ri-settings-line align-middle"></i>
                    </router-link>
                  </b-col>
                </b-row>

              </b-list-group-item>
            </b-list-group>
          </b-overlay>
        </b-col>
      </b-row>

    </b-card>

    <div v-if="showCatalogWidgets" class="col-xl-3 col-lg-4 pl-0">
      <div class="card">
        <div class="card-body">
          <b-list-group>
<!--            <b-list-group-item-->
<!--                v-if="existingWidgets.length"-->
<!--                class="border-top-0 border-left-0 border-right-0 pl-2 d-flex justify-content-between align-items-center"-->
<!--                button-->
<!--                :active = "activeFilter === 'existingWidgets'"-->
<!--                active-class="secondary"-->
<!--                @click="activeFilter = 'existingWidgets'"-->
<!--            >-->
<!--              <span>-->
<!--                <i class="mdi mdi-star mr-2"></i>-->
<!--                {{ $t('Tags.TagEdit.Step1.CreatedWidgets') }}-->
<!--              </span>-->
<!--              <b-badge variant="primary" class="pt-1" pill>{{ existingWidgets.length }}</b-badge>-->
<!--            </b-list-group-item>-->

            <b-list-group-item
                v-for="(filter, index) in filters"
                :key = index
                class="border-top-0 border-left-0 border-right-0 pl-2 d-flex justify-content-between align-items-center"
                button
                :active = "filter.id===activeFilter"
                active-class="secondary"
                @click="activeFilter=filter.id"
            >
              <span>
                <i :class="filter.ico + ' mr-2'"></i>
                {{filter.name}}
              </span>
<!--              <b-badge variant="primary" class="pt-1" pill>{{ filter.widgets.length }}</b-badge>-->
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </div>

    <div v-if="showCatalogWidgets" class="col-lg-9 pr-0">
      <div class="card" >
        <div class="card-body">

          <b-overlay :show="loadingWidgets" rounded="sm">
          <!------------------------------------------------------------------------------- existingWidgets ---->
<!--            <div v-if="activeFilter === 'existingWidgets'" class="row no-gutters">-->
<!--              <div-->
<!--                  v-for="(widget, i) in existingWidgets"-->
<!--                  :key="i"-->
<!--                  class="col-xl-4 col-sm-6"-->
<!--              >-->
<!--                <div class="product-box">-->

<!--  &lt;!&ndash;                <img width="200" draggable="false" :src="items[item].img" target="blank" />&ndash;&gt;-->
<!--                  <div class="text-center">-->
<!--                    <h5 class="font-size-15">-->
<!--                      #{{widget.id}}-->
<!--                      <span v-if="!widget.name">{{ $t('Tags.Table.NoName') }}</span>-->
<!--                      <span v-if="widget.name">{{widget.name}}</span>-->
<!--                    </h5>-->
<!--                    <router-link class="btn btn-primary mr-2" :to="'/constructor/' + widget.id">-->
<!--                      {{ $t('Tags.TagEdit.Step1.GoToConstructor') }}-->
<!--                    </router-link>-->
<!--                    <b-button variant="success" @click="selectWiddget(widget.id)">{{ $t('General.Base.Select') }}</b-button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->


          <!------------------------------------------------------------------------------- !existingWidgets ---->
            <div v-if="activeFilter !== 'existingWidgets'" class="row no-gutters">
              <div
                  v-for="(item, i) in items"
                  :key="i"
                  class="col-xl-4 col-sm-6"
              >
                  <div
                      class="product-box text-center"
                      v-on:mouseover = "hoverWidget = item.id"
                      v-on:mouseleave = "hoverWidget = null"
                  >
                    <div v-if="hoverWidget === item.id" class="hoverBlock" @click="goCustomTag(item.id)">

                      <h4 class="text-white">
                        <i class="mdi  mdi-widgets-outline" style="font-size: 36px"></i> <br>
                        {{ $t('General.Base.Select') }}
                      </h4>
                    </div>
                    <div class="containerImg">

                      <b-carousel v-if="item.img && item.img.length > 1">
                        <b-carousel-slide
                            v-for="(img, n) in item.img"
                            :key="n"
                            :img-src="'https://storage.eefim.com/' + img">
                        </b-carousel-slide>
                      </b-carousel>

                      <img v-else draggable="false" :src="item.localImg === undefined ? 'https://storage.eefim.com/' + item.img : item.localImg" />
                    </div>

                    <div class="text-center mt-3">
                      <h5 class="font-size-15">{{item.name}}</h5>
                      <p class="font-size-12 mb-1 descriptionText">{{item.desc}}</p>
                    </div>
                  </div>
              </div>
            </div>

            <b-pagination class="mt-4" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
          </b-overlay>
        </div>


      </div>
    </div>

  </div>
</template>

<script>
// import router from "@/router";
import {tagsService} from "@/helpers/fakebackend/tags.service";
import {widgetService} from "@/helpers/fakebackend/widgets.service";
import {isArray, isString} from "lodash";

export default {
  name: "tags",
  data: function() {
    return {
      loading: true,
      loadingVirsionsWidget: true,
      loadingWidgets: true,
      activeFilter: 1,
      hoverWidget: null,
      showDescriptionTextInNotSaveAleert: false,
      filters: [{id: 4, name: 'Заготовки', ico: 'mdi mdi-cog-outline'}],
      existingWidgets: [],
      items: [],
      versionsCurrentTag: null,
      showCatalogWidgets: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 6,
      sourceWidget:[]
      // customItems: {
      //   1: [
      //     {
      //       name: 'Конструктор',
      //       desc: 'Сделай сам!',
      //       localImg: '/img/elemImgDefault.0781ce71.png',
      //       ico: 'mdi  mdi-widgets-outline',
      //       id: 0
      //     }
      //   ]
      // }
    }
  },
  computed: {
    checkSelectTag: function() {
      if (this.getTagEdit.type == 0 && this.getTagEdit.tag_source_id !== null) {
        return true
      }
      return false
    },
    getTagEdit: function() {
      return this.$store.getters['tag/getTag']
    },
    // checkNotSaveWidget: function() {
    //   if (!this.checkSelectTag) {
    //     return false
    //   }
    //
    //   // if (this.$route.params.id)
    //   if (this.getTagEdit.saveVersion !== this.getTagEdit.version) {
    //     return true
    //   }
    //
    //   return false
    // }
  },
  watch: {
    activeFilter: function() {
      if (this.activeFilter === 'existingWidgets') {
        return
      }
      this.getTemplates()
    },
    currentPage: function () {
        this.getTemplates()
    }
  },
  methods: {
    isString,
    isArray,
    ...tagsService,
    ...widgetService,
    publicationTag(tag_id) {
      this.publicTag(tag_id).then(() => {
        this.$store.commit('tag/setPublicSourceTagId', this.getTagEdit.tag_source_id)
      }).catch(() => {

      })
    },
    goCustomTag(template_id)
    {
      let data = {
        project_id: this.$store.getters['tag/getTag'].project_id,
        // name: this.$store.getters['tag/getTag'].name,
        template_id: template_id,
        setToTag: true
      }

      if (this.$route.params.id !== '0') {
        data.tag_id = this.$route.params.id
      }

      this.createTag(data).then(tag => {
        if (this.$route.params.id !== '0') {
          this.getAllWidgetVersions(data.tag_id)
        }

        this.$store.commit('tag/setSourceTagId', tag.id)
        this.$store.dispatch('tag/getFieldsTag')
        this.$store.dispatch('tag/saveTagLocal')
        this.showCatalogWidgets = false
        // router.push('/constructor/' + tag.id + '?r=tags/' + this.getTagEdit.id)
      })
    },
    selectWiddget(widgetId) {
      this.$store.commit('tag/setSourceTagId', widgetId)
      this.$store.dispatch('tag/getFieldsTag')
    },
    changeWidget(){
      this.showCatalogWidgets = true
      // this.$store.commit('tag/setSourceTagId', null)
    },
    getTemplates() {
      if (this.activeFilter === 'existingWidgets') {
        return
      }

      const data = {
          page: this.currentPage,
          tag: this.activeFilter
      }


      // this.items = []
      // if (this.customItems[this.activeFilter] !== undefined) {
      //   this.items = this.customItems[this.activeFilter]
      // }
      this.loadingWidgets = true
      this.getTemplateWidgets(data).then(result => {
        this.items = result.data
        this.loadingWidgets = false
        this.totalRows = result.count
      })
    },
    saveWidget(){
      let data = {
        tag_id: this.getTagEdit.tag_source_id,
        data: JSON.stringify(this.getTagEdit)
      }
      this.updateWidget(data).then(() => {
        data.data.saveVersion = data.data.version
        this.$store.commit('tag/setTag', data.data)
      })
    },
    getAllWidgetVersions(tag_id) {
      if (tag_id === '0') {
        this.loadingVirsionsWidget = false
        return
      }
      this.loadingVirsionsWidget = true
      this.getWidgets({tag_id:tag_id}).then((data) => {
        this.versionsCurrentTag = data
        this.loadingVirsionsWidget = false
      })
    },
    selectOverVersionWidget(widget_id) {
      this.loadingVirsionsWidget = true
      this.setWidgetForTag(this.getTagEdit.id, widget_id).then(() => {
        this.$store.commit('tag/setSourceTagId', widget_id)
        this.$store.dispatch('tag/saveTagLocal')
        this.getAllWidgetVersions(this.getTagEdit.id)
      })
    }
  },
  mounted() {
    // this.getWidgets({project_id: this.$store.getters['tag/getTag'].project_id}).then(result => {
    //   this.existingWidgets = result
    // })

    this.getTemplateWidgetsTags().then(result => {
      this.filters = [...this.filters, ...result]
    })

    this.getTemplates()

    if (this.checkSelectTag) {
      this.getAllWidgetVersions(this.getTagEdit.id)
      this.showCatalogWidgets = false
    } else {
      this.loadingVirsionsWidget = false
      this.showCatalogWidgets = true
    }

  }
}
</script>

<style scoped>

</style>
