export const GroupsEvent = {
    Actions: ['init', 'click', 'url', 'tagRelationIsset', 'tagRelationEmpty'],
    Behaviors: ['click', 'url', 'timeInPage', 'leaveFromSite'],
    Properties: ['langBrowser', 'langSite', 'cookies']
}
export const ListEvents = {
    init: {
        systemName: 'init',
        ico: 'ri-shut-down-line',
        description: true,
        group: 'Actions',
        settings: []
    },
    click: {
        systemName: 'click',
        ico: 'mdi mdi-target',
        description: true,
        group: 'Actions',
        settings: [
            {
                name: 'selector_css',
                type: 'cssSelector',
            }
        ]
    },
    url: {
        systemName: 'url',
        ico: 'mdi mdi-target',
        description: true,
        group: 'Behaviors',
        settings: [
            {
                name: 'url',
                type: 'conditionString',
                condition: [
                    '=', '!=', 'include', 'noInclude', 'contains', 'noContains', 'includesOneOf', 'noIncludesOneOf'
                ]
            }
        ]
    },
    timeInPage: {
        systemName: 'timeInPage',
        ico: 'ri-timer-line',
        description: true,
        group: 'Behaviors',
        settings: [
            {
                name: 'time',
                type: 'time'
            }
        ]
    },
    timeInSite: {
        systemName: 'timeInSite',
        ico: 'ri-timer-line',
        description: true,
        group: 'Behaviors',
        settings: [
            {
                name: 'time',
                type: 'time'
            }
        ]
    },
    leaveFromSite: {
        systemName: 'leaveFromSite',
        ico: 'ri-timer-line',
        description: true,
        group: 'Behaviors'
    },
    tagRelationIsset: {
        systemName: 'tagRelationIsset',
        ico: 'mdi mdi-target',
        description: true,
        group: 'Actions',
        settings: [
            {
                name: 'tag',
                type: 'select',
                required: true,
                multiple: false,
                mode: 'tag'
            },
            {
                name: 'rangeDate',
                type: 'rangeDate',
                required: false,
            },
            // {
            //     name: 'longSince',
            //     type: 'longSince'
            // }
        ]
    },
    tagRelationEmpty: {
        systemName: 'tagRelationEmpty',
        ico: 'mdi mdi-target',
        description: true,
        group: 'Actions',
        settings: [
            {
                name: 'tag',
                type: 'select',
                required: true,
                multiple: false,
                mode: 'tag',
                options: [
                    {
                        'code': 'self',
                        'name': 'SelfTag'
                    }
                ]
            },
            {
                name: 'rangeDate',
                type: 'rangeDate',
                required: false
            },
            {
                name: 'time',
                type: 'time',
                required: false
            }
            // {
            //     name: 'longSince',
            //     type: 'longSince'
            // }
        ]
    },
    langBrowser: {
        systemName: 'langBrowser',
        ico: 'mdi mdi-target',
        description: true,
        group: 'Properties',
        settings: [
            {
                name: 'lang',
                type: 'select',
                required: true,
                mode: 'lang',
                multiple: true,
                condition: [ 'contains', 'noContains' ]
            }
        ]
    },
    langSite: {
        systemName: 'langSite',
        ico: 'mdi mdi-target',
        description: true,
        group: 'Properties',
        settings: [
            {
                name: 'lang',
                type: 'select',
                required: true,
                mode: 'lang',
                multiple: true,
                condition: [ 'contains', 'noContains' ]
            }
        ]
    },
    cookies: {
        systemName: 'cookies',
        ico: 'mdi mdi-target',
        description: true,
        group: 'Behaviors',
        settings: [
            {
                name: 'name',
                type: 'string',
                required: true,
            },
            {
                name: 'cookies',
                type: 'conditionString',
                required: false,
                condition: [
                   'isset', 'empty', '=', '!=', 'include', 'noInclude', 'contains', 'noContains', 'includesOneOf', 'noIncludesOneOf'
                ]
            }
        ]
    },
}
