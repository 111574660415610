<template>
  <div>

    <label for="triggerFieldSelectDateFrom">
      {{$t('Tags.TagEdit.Events.' + eventName + '.Fields.' + setting.name + '.name')}}
    </label>

    <b-form-group
      :label = "$t('General.Base.From')"
      label-cols="2"
      class="mb-2"
    >
      <b-input-group>
        <b-form-datepicker
            id="triggerFieldSelectDateFrom"
            v-model="dateFrom"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            :class="{'is-invalid': validate && $v.dateFrom.$error }"
        ></b-form-datepicker>
        <b-form-timepicker
            v-model="timeFrom"
            :class="{'is-invalid': validate && $v.timeFrom.$error }"
        ></b-form-timepicker>
      </b-input-group>
    </b-form-group>

    <b-form-group
        :label = "$t('General.Base.To')"
        label-cols="2"
        class="mb-2"
    >
    <b-input-group >
        <b-form-datepicker
            id="triggerFieldSelectDateTo"
            v-model="dateTo"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            :class="{'is-invalid': validate && $v.dateTo.$error }"
        ></b-form-datepicker>
        <b-form-timepicker
            v-model="timeTo"
            :class="{'is-invalid': validate && $v.timeTo.$error }"
        ></b-form-timepicker>
      </b-input-group>
    </b-form-group>

<!--@TODO: добавить пояснительный текст;-->

<!--    from <span v-if="textHelperFrom">{{textHelperFrom | date}}</span>-->
<!--    to <span v-if="textHelperTo">{{textHelperTo | date}}</span>-->

  </div>
</template>

<script>
import {maxLength} from "vuelidate/lib/validators";

export default {
  name: "rangeDate",
  props: ["dataTag", "setting", "value", "validate", "eventName"],
  data: function () {
    return {
      // dateFrom: null,
      // timeFrom: null, // '00:00:00'
      // dateTo: null,
      // timeTo: null,
    }
  },
  validations: {
    dateFrom: { maxLength: maxLength(250)},
    timeFrom: { maxLength: maxLength(250) },
    dateTo: { maxLength: maxLength(250) },
    timeTo: { maxLength: maxLength(250) },
  },
  computed: {
    textHelperFrom: function() {
      return this.getDateFromUnixTime()
    },
    textHelperTo: function() {
      return this.getDateToUnixTime()
    },
    dateFrom: {
      get: function() {
        if (!this.value || !this.value.dateTimeFrom) {
          return ''
        }
        return new Date(this.value.dateTimeFrom * 1000)
      },
      set: function(val) {
        this.$v.$touch();
        let date = null
        if (val !== null) {
          date = Date.parse(val + ' ' + this.timeFrom) / 1000
        }

        let data = {
          dateTimeFrom: date,
          dateTimeTo: this.value.dateTimeTo
        }
        this.saveTrigger(data)
      }
    },
    timeFrom: {
      get: function() {
        if (!this.value || !this.value.dateTimeFrom) {
          return '00:00'
        }
        return this.$options.filters.date(this.value.dateTimeFrom, 'time');
      },
      set: function(val) {
        this.$v.$touch();
        if (!this.dateFrom) {
          return
        }
        if (val === null) {
          val = '00:00'
        }
        let time = val.split(":")
        this.dateFrom.setHours(time[0])
        this.dateFrom.setMinutes(time[1])
        let data = {
          dateTimeFrom: Math.floor(this.dateFrom.getTime() / 1000),
          dateTimeTo: this.value.dateTimeTo
        }
        this.saveTrigger(data)
      }
    },

    dateTo: {
      get: function() {
        if (!this.value || !this.value.dateTimeTo) {
          return ''
        }
        return new Date(this.value.dateTimeTo * 1000)
      },
      set: function(val) {
        this.$v.$touch();
        let date = null
        if (val !== null) {
          date = Date.parse(val + ' ' + this.timeTo) / 1000
        }

        let data = {
          dateTimeFrom: this.value.dateTimeFrom,
          dateTimeTo: date
        }
        this.saveTrigger(data)
      }
    },
    timeTo: {
      get: function() {
        if (!this.value || !this.value.dateTimeTo) {
          return '00:00'
        }
        return this.$options.filters.date(this.value.dateTimeTo, 'time');
      },
      set: function(val) {
        this.$v.$touch();
        if (!this.dateTo) {
          return
        }
        if (val === null) {
          val = '00:00'
        }
        let time = val.split(":")
        this.dateTo.setHours(time[0])
        this.dateTo.setMinutes(time[1])
        let data = {
          dateTimeFrom: this.value.dateTimeFrom,
          dateTimeTo: Math.floor(this.dateTo.getTime() / 1000)
        }
        this.saveTrigger(data)
      }
    }
  },
  methods: {
    getDateFromUnixTime() {
      if (!this.dateFrom) {
        return false
      }
      return Date.parse(this.dateFrom + ' ' + this.timeFrom) / 1000
    },
    getDateToUnixTime() {
      if (!this.dateTo) {
        return false
      }
      return Date.parse(this.dateTo + ' ' + this.timeTo) / 1000
    },
    saveTrigger(data) {
      this.$emit('change', data)
      this.$emit('input', data)
    }
  }
}
</script>

<style scoped>

</style>