<template>
  <b-form-group
      label-cols-sm="4"
      :label="$t('Tags.TagEdit.Events.' + eventName + '.Fields.' + setting.name + '.name')"
      :label-for="'conditionString' + setting.name"
  >

    <b-input-group>
      <b-form-select
          :class="isString(valueField) ? 'col-4' : 'col-12'"
          :options="conditionOptions"
          v-model="valueCondition"
          v-b-tooltip.hover
          :title="$t('Tags.TagEdit.Fields.conditionString.conditionFieldTooltip')"
      ></b-form-select>

      <b-form-input
          v-if="isString(valueField) && !['empty', 'isset'].includes(valueCondition)"
          :placeholder = "$t('General.Form.NoSet')"
          type="text"
          v-model="valueField"
          :name="'conditionString' + setting.name"
          v-b-tooltip.hover
          :title="$t('Tags.TagEdit.Fields.conditionString.fieldValueTooltip')"
          :class="{'col-8': true, 'is-invalid': validate && $v.valueField && $v.valueField.$error }"
      ></b-form-input>
      <div v-if="validate && $v.valueField && $v.valueField.$error" class="invalid-feedback">
        <span v-if="$v.valueField && !$v.valueField.required">{{ $t('General.Form.Validate.Required') }}</span>
        <span
            v-if="$v.valueField && !$v.valueField.maxLength"
        >{{ $t('General.Form.Validate.Maxlength', {count: $v.valueField.$params.maxLength.max}) }}</span>
      </div>
    </b-input-group>


    <div v-if="patternTypeArray.includes(valueCondition)" class="mt-1" >

      <b-input-group
          v-for="(field, index) in valueField"
          :key="index"
          class="mb-1"
      >
        <b-form-input
            :placeholder="$t('General.Form.NoSet')"
            type="text"
            class="col-12 is-valid"
            v-model="valueField[index]"
        >
        </b-form-input>

        <div class="input-group-append">
          <b-button
              class=""
              variant="danger"
              v-on:click="valueField.splice(index, 1)"
              v-b-tooltip.viewport.hover.ds500.dh0.html.left
              :title = "$t('General.Base.DeleteValue')"
          >
            <i class="fas fa-minus"></i>
          </b-button>
        </div>
      </b-input-group>

      <b-input-group class="mb-1">
        <b-form-input
            :placeholder="$t('General.Form.NoSet')"
            type="text"
            class="col-12"
            v-model="newValueForArray"
        >
        </b-form-input>

        <div class="input-group-append">
          <b-button
              class=""
              variant="success"
              v-on:click.stop="addValueForArray()"
              v-b-tooltip.viewport.hover.ds500.dh0.html.left
              :title = "$t('General.Base.AddValue')"
          >
            <i class="fas fa-plus"></i>
          </b-button>
        </div>
      </b-input-group>

    </div>


    <b-form-text v-if="setting.description">
      {{setting.description}}
      <i
          v-if="setting.tooltip"
          v-b-tooltip.hover
          :title="setting.tooltip"
          class="far fa-question-circle"></i>
    </b-form-text>

  </b-form-group>
</template>

<script>
import { isString, isArray} from "lodash";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
  name: "conditionString",
  props: ["dataTag", "setting", "value", "validate", "eventName"],
  data: function () {
    return {
      valueCondition: null,
      valueField: '',
      conditionOptions: [],
      patternConditions: {
        '=': { value: '=', text: this.$t('Tags.TagEdit.Over.Conditions.=') },
        '!=': { value: '!=', text: this.$t('Tags.TagEdit.Over.Conditions.!=') },
        '<': { value: '<', text: this.$t('Tags.TagEdit.Over.Conditions.<') },
        '>': { value: '>', text: this.$t('Tags.TagEdit.Over.Conditions.>') },
        '<=': { value: '<=', text: this.$t('Tags.TagEdit.Over.Conditions.<=') },
        '>=': { value: '>=', text: this.$t('Tags.TagEdit.Over.Conditions.>=') },
        'include': { value: 'include', text: this.$t('Tags.TagEdit.Over.Conditions.include') },
        'noInclude': { value: 'noInclude', text: this.$t('Tags.TagEdit.Over.Conditions.noInclude') },
        'contains': { value: 'contains', text: this.$t('Tags.TagEdit.Over.Conditions.contains') },
        'noContains': { value: 'contains', text: this.$t('Tags.TagEdit.Over.Conditions.noContains') },
        'includesOneOf': { value: 'includesOneOf', text: this.$t('Tags.TagEdit.Over.Conditions.includesOneOf') },
        'noIncludesOneOf': { value: 'noIncludesOneOf', text: this.$t('Tags.TagEdit.Over.Conditions.noIncludesOneOf') },
        'isset': { value: 'isset', text: this.$t('Tags.TagEdit.Over.Conditions.isset') },
        'empty': { value: 'empty', text: this.$t('Tags.TagEdit.Over.Conditions.empty') }
      },
      patternTypeArray: ['contains', 'noContains', 'includesOneOf', 'noIncludesOneOf'],
      newValueForArray: '',
      oldValueArray: null
      // pattern: {
      //   value: null
      // }
    }
  },
  validations() {
    if (this.valueCondition === 'isset') {
        return {}
    }
    return {
        valueField: { required, maxLength: maxLength(250)}
    }
  },
  watch: {
    valueCondition: function(val) {
      if (this.patternTypeArray.includes(val)) {
        if (this.oldValueArray) {
          this.valueField = this.oldValueArray
        } else if (this.valueField !== '') {
          if (isString(this.valueField)) {
            this.valueField = [this.valueField]
          }
        } else {
          this.valueField = []
        }
      } else if (isArray(this.valueField)) {
        this.oldValueArray = this.valueField
        this.valueField = this.valueField.join(', ')
      }


      this.$v.$touch();
      let data = {condition: val, value: this.valueField}
      this.$emit('change', data)
      this.$emit('input', data)
    },
    valueField: function(val) {
      this.$v.$touch();
      let data = {condition: this.valueCondition, value: val}
      this.$emit('change', data)
      this.$emit('input', data)
    },
    // value: function (val) {
    //   this.valueField = val
    // }
  },
  methods: {
    isString(string) {
      return isString(string)
    },
    addValueForArray() {
      if (this.newValueForArray === '') {
        return
      }
      this.valueField.push(this.newValueForArray)
      this.newValueForArray = ''
    }
  },
  mounted() {
    this.setting.condition.forEach(elem => {
      this.conditionOptions.push(
          this.patternConditions[elem]
      )
    })
    if (this.value) {
      this.valueCondition = this.value.condition
      this.valueField = this.value.value
    } else {
      this.valueCondition = this.conditionOptions[0].value
    }
  },
}
</script>

<style scoped>

</style>
