<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <LoaderBig v-if="loading" />

    <b-card v-if="loading === false && project === false">
      <div class="text-center mt-5">
        <h4>{{$t('Tags.TagEdit.NoProject.Title')}}</h4>
        <small>{{$t('Tags.TagEdit.NoProject.Text')}}</small>
        <div class="mt-4">
          <router-link class="btn btn-success btn-lg" to="/projects">
            {{$t('Tags.TagEdit.NoProject.ToProjects')}}
            <i class="ri-arrow-right-line align-middle ml-2"></i>
          </router-link>
        </div>
      </div>
    </b-card>

    <form-wizard v-if="project === true" color="#5664d2" ref="formWizard" >
<!--      :before-change="validateStepSelectTag" -->
      <tab-content :title="$t('Tags.TagEdit.Step1.Name')" icon="mdi  mdi-widgets-outline" :before-change="validateStepSelectTag">
        <Tags v-if="!loading" />
      </tab-content>
      <tab-content :title="$t('Tags.TagEdit.Step2.Name')" icon="mdi  mdi-bullseye-arrow">
        <ConditionActivate />
      </tab-content>
      <tab-content :title="$t('Tags.TagEdit.Step3.Name')" icon="mdi mdi-google-analytics">
        <DataControl />
      </tab-content>

      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <b-button
              v-if="props.activeTabIndex > 0"
              class="wizard-btn"
              @click="props.prevTab(), saveLocale()"
              :style="props.fillButtonStyle">
            {{$t('General.Base.Back')}}
          </b-button>
        </div>
        <div class="wizard-footer-right">
          <b-button
              v-if="!props.isLastStep"
              @click="props.nextTab(), saveLocale(), saveTag()"
              class="wizard-footer-right wizard-btn"
              :style="props.fillButtonStyle">
            {{$t('General.Base.Next')}}
          </b-button>

          <b-button
              v-else
              @click="complete()"
              class="wizard-footer-right finish-button wizard-btn"
              :style="props.fillButtonStyle">
            {{props.isLastStep ? $t('General.Base.Save') : $t('General.Base.Next')}}
          </b-button>
        </div>
      </template>

    </form-wizard>

<!--    <pre>-->
<!--      {{this.$store.getters['tag/getTag']}}-->
<!--    </pre>-->


  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import { FormWizard, TabContent } from "vue-form-wizard";
import ConditionActivate from "./conditionActivate/_conditionActivate"
import Tags from "./tags/_tags"
import DataControl from "./dataControl/_dataControl"
import LoaderBig from "@/components/LoaderBig"

export default {
  name: "TagEdit",
  components: { Layout, PageHeader, FormWizard, TabContent, ConditionActivate, Tags, DataControl, LoaderBig },
  page: {
    // title: this.widget_id === null ? "Новый виджет" : 'Виджет',
    title: "Tag",
    meta: [{ name: "Tag" }],
  },
  data: function () {
    return {
      project: null,
      title: this.$t('Tags.TagEdit.Title'),
      items: [
        {
          text: this.$t('General.Breadcrumbs.Main'),
          href: "/",
        },
        {
          text: this.$t('General.Breadcrumbs.Tags'),
          href: "/tags"
        },
        {
          text: this.$t('General.Breadcrumbs.NewTags'),
          active: true,
        },
      ],
      widget_id: null,
      loading: true
    }
  },
  methods: {
    saveLocale: function() {
      this.$store.dispatch('tag/saveTagLocal')
    },
    complete: function () {
      this.$store.dispatch('tag/saveTag').then(data => {
        if (data) {
          this.$router.push('/tags')
          this.$bvToast.toast( this.$t('Tags.General.TagCreated'), {
            title: this.$t('General.Toast.TitleNotification'),
            variant: 'success',
            solid: true
          })
        }
      })
    },
    saveTag: function() {
      this.$store.dispatch('tag/saveTag', false).then(data => {
        if (data) {
          if (this.$route.params.id === '0') {
            this.$route.params.id = data.id
            window.history.pushState({}, null, data.id)
          }
        }
      })
    },
    validateStepSelectTag: function() {
      if (this.$store.getters['tag/getTag'].tag_source_id === null) {
        this.$bvToast.toast( this.$t('Tags.General.NeedSelectWidget'), { // this.$t('Tags.TagEdit.NewEvent.DeleteTriggerSuccess')
          title: this.$t('General.Toast.Title.Error'),
          variant: 'danger',
          solid: true
        })
        return false
      }
      return true
    },
    // validateStepTriggers: function() {
      // if (this.$store.getters['tag/getTag'].tag_source_id === null) {

    // }
  },
  mounted() {
    // this.$store.dispatch('tag/init', this.$route.params.id)
    //
    // if (this.$route.params.id !== '0') {
    //   this.$refs.formWizard.activateAll()
    //   this.widget_id = { id: parseInt(this.$route.params.id) }
    //   this.title = 'Виджет #' + this.widget_id.id + ' Попапчик'
    //   this.items[2].text = 'Виджет #' + this.widget_id.id
    //   return
    // }


    this.$store.dispatch('projects/getCurrentProjects').then((project_id) => {
      if (!project_id) {
        this.project = false
        this.loading = false
        return
      }

      this.project = true

      this.$store.dispatch('tag/init', this.$route.params.id).then(() => {
        this.loading = false

        if (this.$route.params.id !== '0') {
          this.$refs.formWizard.activateAll()
          return
        }

        this.$store.commit('tag/setProject',
            {
              project_id: this.$store.getters['projects/getCurrentProjects'].id
            }
        )
      })
    })
  }
}
</script>

<style scoped>

</style>
