<template>
  <b-form-group
      label-cols-sm="4"
      :label="$t('Tags.TagEdit.Events.' + eventName + '.Fields.' + setting.name + '.name')"
      :label-for="setting.name"
  >
    <b-form-input
        placeholder="No set"
        type="text"
        v-model="valueString"
        :name="setting.name"
        style="width: 100%"
        v-b-tooltip.hover
        :title="setting.tooltip"
    ></b-form-input>

    <b-form-text v-if="setting.description">
      {{setting.description}}
      <i
          v-if="setting.tooltip"
          v-b-tooltip.hover
          :title="setting.tooltip"
          class="far fa-question-circle"></i>
    </b-form-text>
  </b-form-group>
</template>

<script>
export default {
  name: "string",
  props: ["dataTag", "setting", "value", "eventName"],
  data: function () {
    return {}
  },
  computed: {
      valueString: {
          get: function () {
              return this.value
          },
          set: function (val) {
              this.$emit('change', val)
              this.$emit('input', val)
          }
      }
  },
  mounted() {
      // console.log(this.value)
    // if (!this.value) {
    //   this.value = this.pattern
    // }
  }
}
</script>

<style scoped>

</style>
