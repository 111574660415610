<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">

          <b-form-group
              id="example text"
              label-cols-sm="2"
              label-cols-lg="2"
              :label="$t('Tags.TagEdit.Step3.FieldNameWidget')"
              label-for="leadTimeText"
              class="mb-5"
          >
            <b-form-input
                for="widgetName"
                v-model="widgetName"
                placeholder="Not set"
            ></b-form-input>
          </b-form-group>

          <b-row>
            <b-col class="">
              <h5>{{$t('Tags.TagEdit.Step3.FieldsFiendName')}}</h5>
              <b-table hover :items="fieldsForTable"></b-table>
            </b-col>
            <b-col class="pl-5">
              <ExportMethods
                  @onChooseEditMethod = "choseMethod"
              />
            </b-col>
          </b-row>

          <editMethod
              v-if="editMethodHandler !== null"
              :editMethod = editMethodHandler
              :handler_id = "handler_id_for_edit"
              @onCloseHandler = "closeHandler"
          />

          <h5 class="mt-5">{{$t('Tags.TagEdit.Step3.ExportMethods')}}</h5>

          <b-row>
            <b-col
                v-for="(method, index) in LIstExportMethods"
                :key="index"
                class="col-2 "
            >
              <a
                  href="#"
                  @click.prevent.stop="choseMethod({editMethod: index, handler_id_for_edit: null})"
                  class="text-reset font-size-16"
              >
                <img :src="require('@/assets/images/export_methods/' + method.img )" alt="" class="w-100"/>
                <p class="text-center">
                  {{$t('Tags.TagEdit.Handlers.' + index + '.Name')}}
                </p>
              </a>

            </b-col>
          </b-row>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExportMethods from './_exportMethods'
import editMethod  from "./exportMethods/_editMethod";
import {LIstExportMethods} from "@/helpers/Tags/LIstExportMethods";

export default {
  name: "dataControl",
  components: { ExportMethods, editMethod },
  data: function () {
    return {
      LIstExportMethods: LIstExportMethods,
      editMethodHandler: null,
      handler_id_for_edit: null
    }
  },
  computed: {
    tag: function() {
      return this.$store.getters['tag/getTag']
    },
    widgetName: {
      get: function() {
        return this.$store.getters['tag/getTag'].name
      },
      set: function(val) {
        this.$store.commit('tag/setTagName', val)

      }
    },
    fieldsForTable: function () {
      if (!this.tag.tagSourceId) {
        return []
      }
      let data = [];
      for (let i in this.tag.tagSourceId.tagFieldName) {
        data.push({
          win_id: this.tag.tagSourceId.tagFieldName[i].win_id,
          field_id: this.tag.tagSourceId.tagFieldName[i].field_id,
          user_name: this.tag.tagSourceId.tagFieldName[i].user_name,
          type_field: this.tag.tagSourceId.tagFieldName[i].type_field
        })
      }
      return data
    }
  },
  methods: {
    closeHandler: function() {
      this.editMethodHandler = null
      this.handler_id_for_edit = null
    },
    choseMethod: function (obj) {
      // console.log(obj)
      this.editMethodHandler = obj.editMethod
      this.handler_id_for_edit = obj.handler_id_for_edit
    },
  }
}
</script>

<style scoped>

</style>
