<template>
  <b-modal
      id="modal-lead-time"
      size="lg"
      :title="$t('Tags.TagEdit.Step2.LeadTime.Modal.Title')"
      title-class="font-18"
      ok-only
  >
<!--    @TODO: Добавить ссылки на статьи-->
    <p class="mb-4" v-html="$t('Tags.TagEdit.Step2.LeadTime.Modal.Desc')"></p>

    <div v-if="leadTime !== null">
      <div class="custom-control custom-radio mb-3">
        <input
            type="radio"
            id="lead-time-page"
            name="lead-time"
            class="custom-control-input"
            :checked = "leadTime === 'page'"
            @change = "leadTime = 'page'"
        />
        <label class="custom-control-label" for="lead-time-page">{{$t('Tags.TagEdit.Step2.LeadTime.Modal.LeadTimePage')}}</label>
        <b-form-text>({{$t('Tags.TagEdit.Step2.LeadTime.Modal.LeadTimePageDesc')}})</b-form-text>
      </div>
      <div class="custom-control custom-radio mb-3">
        <input
            type="radio"
            id="lead-time-session"
            name="lead-time"
            class="custom-control-input"
            :checked = "leadTime === 'session'"
            @change = "leadTime = 'session'"
        />
        <label class="custom-control-label" for="lead-time-session">{{$t('Tags.TagEdit.Step2.LeadTime.Modal.LeadTimeSession')}}</label>
        <b-form-text v-html="$t('Tags.TagEdit.Step2.LeadTime.Modal.LeadTimeSessionDesc')"></b-form-text>
      </div>
      <div class="custom-control custom-radio mb-3">
        <input
            type="radio"
            id="lead-time-alltime"
            name="lead-time"
            class="custom-control-input"
            :checked = "leadTime === 0 "
            @change = "leadTime = 0"
        />
        <label class="custom-control-label" for="lead-time-alltime">
          {{$t('Tags.TagEdit.Step2.LeadTime.Modal.LeadTimeAll')}}
        </label>
        <b-form-text v-html="$t('Tags.TagEdit.Step2.LeadTime.Modal.LeadTimeAllDesc')"></b-form-text>
      </div>
      <div class="custom-control custom-radio mb-5">
        <input
            type="radio"
            id="lead-time-custom"
            name="lead-time"
            class="custom-control-input"
            :checked = "leadTime !== 0 && leadTime !== 'page' && leadTime !== 'session'"
            @change = "leadTime = ''"
        />
        <label class="custom-control-label" for="lead-time-custom">
          {{$t('Tags.TagEdit.Step2.LeadTime.Modal.LeadTimeCustom')}}
        </label>
        <b-form-text>
          {{$t('Tags.TagEdit.Step2.LeadTime.Modal.LeadTimeCustomDesc')}}
        </b-form-text>
      </div>


      <div :class="disabledField ? 'opacity-04' : ''">
        <b-form-group
            id="example text"
            label-cols-sm="2"
            label-cols-lg="2"
            :label="$t('General.Base.Time')"
            label-for="leadTimeText"
        >
          <TermTime
              v-model="leadTimeText"
              inputName = "leadTimeText"
              :inputDisabled = "disabledField"
              :inputClass = "classTimeField"
          />

<!--          <b-form-input-->
<!--              :disabled = disabledField-->
<!--              for="leadTimeText"-->
<!--              :class="classTimeField"-->
<!--              v-model="leadTimeText"-->
<!--              placeholder="2m 10d 15min"-->
<!--          ></b-form-input>-->

        </b-form-group>
      </div>
    </div>
  </b-modal>
</template>

<script>
import TermTime from "@/components/inputs/TermTime";

export default {
  name: "modalLeadTime",
  components: { TermTime },
  props: [ "leadTimeEdit" ],
  data: function() {
    return {
      classTimeField: '',
    }
  },
  computed: {
    leadTime: {
      get: function () {
        if (this.leadTimeEdit === null) {
          return null
        }
        return this.$store.getters['tag/getTag'].tTagGroupTriggers[this.leadTimeEdit.indexGroup].triggersInGroups[this.leadTimeEdit.indexTrigger].lead_time
      },
      set: function (value) {
        this.$store.commit('tag/setLeadTime',
            {
              indexGroup: this.leadTimeEdit.indexGroup,
              indexTrigger: this.leadTimeEdit.indexTrigger,
              lead_time: value
            }
        )
      }
    },
    leadTimeText: {
      get: function () {
        if (this.leadTimeEdit === null) {
          return null
        }
        return this.$store.getters['tag/getTag'].tTagGroupTriggers[this.leadTimeEdit.indexGroup].triggersInGroups[this.leadTimeEdit.indexTrigger].lead_time_text
      },
      set: function(val) {
        this.leadTime = val
        this.$store.commit('tag/setLeadTimeUser',
            {
              indexGroup: this.leadTimeEdit.indexGroup,
              indexTrigger: this.leadTimeEdit.indexTrigger,
              lead_time_text: val
            }
        )
      }
    },
    disabledField: function () {
      if (this.leadTime === 'page' || this.leadTime === 'session' || this.leadTime === 0) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
