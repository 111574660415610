<template>
  <div >

<!--    <b-table hover :items="currentMethods"></b-table>-->
    <h5>{{$t('Tags.TagEdit.Step3.CurrentExportMethods')}}</h5>

    <div >
      <b-row
          v-for="(method, index) in handlers"
          :key="index"
          align-h="between"
          class="border-bottom border-top pt-1 pb-1"
      >
        <b-col cols="auto" class="d-flex align-items-center">
          <img
              style="width: 24px"
              :src="require('@/assets/images/export_methods/' + LIstExportMethods[method.handler].img )"
              alt=""
              class="mr-2"
          >
          <span class="font-size-16">{{$t('Tags.TagEdit.Handlers.' + method.handler + '.Name')}}</span>
        </b-col>
        <b-col cols="auto">

        </b-col>
        <b-col cols="auto" class="pb-1 pt-1">
          <b-button
              v-if="method.status === 1"
              variant="outline-success"
              size="sm"
              @click="handlerOff(method.id)"
          >
            {{ $t("General.Base.On")}}
          </b-button>
          <b-button
              v-if="method.status === 0"
              variant="outline-danger"
              size="sm"
              @click="handlerOn(method.id)"
          >
            {{ $t("General.Base.Off")}}
          </b-button>
          <b-button
              v-b-tooltip.hover.viewport
              :title = "$t('General.Base.Edit')"
              size="sm"
              variant="outline-secondary"
              @click = "choseMethod(method.handler, method.id)"
              class="ml-1"
          >
            <i class="ri-settings-5-line"></i>
          </b-button>
        </b-col>

      </b-row>

    </div>



  </div>
</template>

<script>
import { LIstExportMethods  } from "@/helpers/Tags/LIstExportMethods";

import { tagsService } from '@/helpers/fakebackend/tags.service';

export default {
  name: "exportMethods",
  components: {
  },
  data: function () {
    return {
      LIstExportMethods: LIstExportMethods,
      editMethod: null,
      handler_id_for_edit: null
    }
  },
  computed: {
    handlers: function() {
      return this.$store.getters['tag/getHandlers']
    },
    currentMethods: function () {
      if (!this.tag.tagHandlers) {
        return []
      }
      let data = [];
      for (let i in this.tag.tagHandlers) {
        data.push({
          ico: LIstExportMethods[this.tag.tagHandlers[i].handler].img,
          handler: this.tag.tagHandlers[i].handler
        })
      }
      return data
    }
  },
  methods: {
    ...tagsService,
    closeHandler: function() {
      // this.editMethod = null
      // this.handler_id_for_edit = null
      this.$emit('onChooseEditMethod', { editMethod: null, handler_id_for_edit: null})
    },
    choseMethod: function (handler, handler_id) {
      // this.editMethod = handler
      // this.handler_id_for_edit = handler_id
      this.$emit('onChooseEditMethod', { editMethod: handler, handler_id_for_edit: handler_id})
    },
    handlerOn: function (handler_id) {
      this.handlerTurnOn({id: handler_id}).then(() => {
        this.$store.commit('tag/handlerTurnOn', handler_id)
      })
    },
    handlerOff: function (handler_id) {
      this.handlerTurnOff({id: handler_id}).then(() => {
        this.$store.commit('tag/handlerTurnOff', handler_id)
      })
    }
  }
}
</script>

<style scoped>

</style>