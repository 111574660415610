<template>
  <div>
    <b-form-input
        :disabled = inputDisabled
        :for = "inputFor"
        :class ="classTimeField"
        v-model = "leadTimeText"
        :name = "inputName"
        placeholder="2m 10d 15min"
    ></b-form-input>
    <b-form-text v-if="!disableText">
      {{$t('Components.TermTime.Description')}}
      <b-row class="mt-0" >
        <b-col cols="auto">
          {{$t('Components.TermTime.DescY')}}
        </b-col>
        <b-col cols="auto">
          {{$t('Components.TermTime.DescM')}}
        </b-col>
        <b-col cols="auto">
          {{$t('Components.TermTime.DescW')}}
        </b-col>
        <b-col cols="auto">
          {{$t('Components.TermTime.DescD')}}
        </b-col>
        <b-col cols="auto">
          {{$t('Components.TermTime.DescH')}}
        </b-col>
        <b-col cols="auto">
          {{$t('Components.TermTime.DescMin')}}
        </b-col>
      </b-row>
    </b-form-text>
  </div>
</template>

<script>
export default {
  name: "TermTime",
  props: ["inputDisabled", "inputFor", "inputClass", "inputName", "value", 'disableText'],
  data: function() {
    return {
      classTimeField: '',
      time: {
        lead_time_text: null,
        lead_time_value: null
      }
    }
  },
  computed: {
    leadTimeText: {
      get: function () {
        if (!this.value) {
          return ''
        }
        return this.value.lead_time_text
      },
      set: function(strTime) {
        try {
          let time = null
          if (strTime === '') {
            strTime = null
          } else {
            time = this.strToTime(strTime)
            this.setSuccessTime()
          }


          let value = {
            lead_time_text: strTime,
            lead_time_value: time
          }

          this.$emit('change', value)
          this.$emit('input', value)

        } catch (e) {
          this.setErrorTime()
        }
      }
    }
  },
  methods: {
    strToTime(val) {
      let units = ['y', 'm', 'w', 'd', 'h', 'min', 's']
      let timeFrame = val.split(/([0-9]*)([a-zA-Z]*)/)
      let data = [];

      // try {
      timeFrame.forEach(elem => {
        if (elem === '' || elem === ' ') {
          data.push({numb: null, unit: null})
          return false
        }
        if (data[data.length - 1].numb === null) {
          data[data.length - 1].numb = parseInt(elem)
          if (isNaN(data[data.length - 1].numb)) {
            throw 'Error 1'
          }
          return
        }

        data[data.length - 1].unit = elem.toLowerCase()
        if (!units.includes(data[data.length - 1].unit)) {
          throw 'Error 2'
        }

      });
      // } catch (e) {
      //   this.setErrorTime()
      // }
      // eslint-disable-next-line no-useless-catch
      try {
        return this.calculateTime(data)
      } catch (e) {
        throw e
      }
    },
    calculateTime(data) {
      let time = 0;
      data.forEach(elem => {
        if (!elem.numb || !elem.unit) {
          this.setErrorTime()
          // throw 'Error 3'
          return
        }
        if (elem.unit === 'y') {
          time += elem.numb * 3600 * 24 * 365
        }
        if (elem.unit === 'm') {
          time += elem.numb * 3600 * 24 * 31
        }
        if (elem.unit === 'w') {
          time += elem.numb * 3600 * 24 * 7
        }
        if (elem.unit === 'd') {
          time += elem.numb * 3600 * 24
        }
        if (elem.unit === 'h') {
          time += elem.numb * 3600
        }
        if (elem.unit === 'min') {
          time += elem.numb * 60
        }
        if (elem.unit === 's') {
          time += elem.numb
        }
      })
      if (time === 0) {
        throw 'Error 4'
      }

      return time
    },
    setErrorTime() {
      this.classTimeField = 'is-invalid'
    },
    setSuccessTime() {
      this.classTimeField = 'is-valid'
    }
  }
}
</script>

<style scoped>

</style>